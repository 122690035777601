const delivery_methods = [
  "Kurier międzynarodowy",
  "Kurier GLS",
  "Kurier DHL",
  "Allegro Kurier DHL",
  "Allegro Odbiór w Punkcie DHL",
  "Allegro Automat DHL POP BOX",
  "Kurier UPS",
  "Allegro Kurier UPS",
  "Allegro Odbiór w Punkcie UPS",
  "Kurier DPD",
  "Allegro Kurier DPD",
  "Allegro Odbiór w Punkcie DPD Pickup",
  "Paczkomaty InPost",
  "Allegro Paczkomaty InPost",
  "Erli Paczkomaty InPost",
  "Kurier InPost",
  "Allegro Kurier24 InPost",
  "Allegro miniKurier24 InPost",
  "Erli Kurier InPost - 30 kg",
  "Erli Kurier InPost - 10 kg",
  "Allegro Automaty Paczkowe Packeta",
  "Allegro Odbiór w Punkcie Packeta",
  "Allegro Odbiór w Punkcie Pocztex",
  "Allegro Automat Pocztex",
  "Allegro One Box",
  "Allegro One Box, UPS",
  "Allegro One Box, DPD",
  "Allegro One Punkt",
  "Allegro One Punkt, UPS",
  "Allegro One Punkt, DPD",
  "Allegro International Kurier",
  "Allegro International Odbiór w Punkcie",
  "Allegro International Automaty Paczkowe",
];

const delivery_methods_options = delivery_methods.map(
  (method) => ({ label: method, value: method })
);

export default delivery_methods_options;
