import React, { PureComponent } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Checkbox, Textarea } from "expano-components";
import { updateProduct } from "actions/products";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const CheckField = (props) => <Checkbox {...props} />;
const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: "Treść alertu...",
    }}
  />
);

class AlertsForm extends PureComponent {
  submit = (values) => {
    const { updateProduct, match, current_user } = this.props;
    if (["admin", "warehouseman", "manager"].includes(current_user.role)) {
      let copy_values = { ...values };
      if (["warehouseman"].includes(current_user.role)) {
        copy_values = {
          has_qr_code: values.has_qr_code,
          is_packer_alert_enabled: values.is_packer_alert_enabled,
          packer_alert: values.packer_alert,
          is_warehouseman_alert_enabled: values.is_warehouseman_alert_enabled,
          warehouseman_alert: values.warehouseman_alert,
        };
      }
      return updateProduct(match.params.id, copy_values).catch((err) => {
        throw new SubmissionError(err);
      });
    }
  };

  render() {
    const {
      current_user,
      handleSubmit,
      submitting,
      pristine,
      checkValues,
      initialValues,
    } = this.props;
    const { role } = current_user;

    return (
      <div className="dashboard m-b-0">
        <form noValidate onSubmit={handleSubmit(this.submit)}>
          <header className="dashboard__header dashboard__header--edit">
            <div className="icon__container">
              <InfoIcon />
            </div>
            <h2 className="dashboard__title heading">Alerty i informacje</h2>
          </header>
          <div className="dashboard__form p-t-10">
            {["admin", "manager"].includes(role) ? (
              <>
                <Field
                  label="Alert pakowacza"
                  name="is_packer_alert_enabled"
                  component={CheckField}
                />
                {checkValues.is_packer_alert_enabled && (
                  <div className="p-l-25" style={{ marginTop: "-10px" }}>
                    <Field name="packer_alert" component={TextAreaField} />
                  </div>
                )}
                <Field
                  label="Alert magazyniera"
                  name="is_warehouseman_alert_enabled"
                  component={CheckField}
                />
                {checkValues.is_warehouseman_alert_enabled && (
                  <div className="p-l-25" style={{ marginTop: "-10px" }}>
                    <Field
                      name="warehouseman_alert"
                      component={TextAreaField}
                    />
                  </div>
                )}
                <Field
                  label="Pokazuj zawsze, jako 1 wiersz"
                  name="show_as_one_line"
                  info="Po zaznaczeniu tej opcji Produkt będzie pokazywany na Pakowalni zawsze, jako 1 wiersza niezależne od tego, ile sztuk znajduje się w zamówieniu."
                  component={CheckField}
                />
                <Field
                  label="Skanowanie EANu"
                  name="has_ean"
                  component={CheckField}
                />
                <Field
                  label="Skanowanie QR kodu"
                  name="has_qr_code"
                  component={CheckField}
                />
              </>
            ) : (
              <>
                <Field
                  label="Alert pakowacza"
                  name="is_packer_alert_enabled"
                  component={CheckField}
                />
                {checkValues.is_packer_alert_enabled && (
                  <div className="p-l-25" style={{ marginTop: "-10px" }}>
                    <Field name="packer_alert" component={TextAreaField} />
                  </div>
                )}
                {role === "warehouseman" ? (
                  <>
                    <Field
                      label="Alert magazyniera"
                      name="is_warehouseman_alert_enabled"
                      component={CheckField}
                    />
                    {checkValues.is_warehouseman_alert_enabled && (
                      <div className="p-l-25" style={{ marginTop: "-10px" }}>
                        <Field
                          name="warehouseman_alert"
                          component={TextAreaField}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-group disabled">
                    <div className="check-group">
                      <input
                        id="is_warehouseman_alert_enabled"
                        name="is_warehouseman_alert_enabled"
                        type="checkbox"
                        checked={initialValues.is_warehouseman_alert_enabled}
                        disabled
                      />
                      <label
                        for="is_warehouseman_alert_enabled"
                        className="label-form"
                      >
                        Alert magazyniera
                      </label>
                    </div>
                  </div>
                )}

                <div className="form-group disabled">
                  <div className="check-group">
                    <input
                      id="show_as_one_line"
                      name="show_as_one_line"
                      type="checkbox"
                      checked={initialValues.show_as_one_line}
                    />
                    <label for="show_as_one_line" className="label-form">
                      Pokazuj zawsze, jako 1 wiersz
                    </label>
                    <p className="additional-info">
                      Po zaznaczeniu tej opcji Produkt będzie pokazywany na
                      Pakowalni zawsze, jako 1 wiersza niezależne od tego, ile
                      sztuk znajduje się w zamówieniu.
                    </p>
                  </div>
                </div>
                <div className="form-group disabled">
                  <div className="check-group">
                    <input
                      id="has_ean"
                      name="has_ean"
                      type="checkbox"
                      checked={initialValues.has_ean}
                      disabled
                    />
                    <label for="has_ean" className="label-form">
                      Skanowanie EANu
                    </label>
                  </div>
                </div>
                <Field
                  label="Skanowanie QR kodu"
                  name="has_qr_code"
                  component={CheckField}
                />
              </>
            )}
          </div>
          <div className="dashboard__form__footer">
            <Button
              disabled={pristine || submitting}
              type="save"
              text="Zapisz"
              onClick={handleSubmit(this.submit)}
            />
          </div>
        </form>
      </div>
    );
  }
}
const selector = formValueSelector("alerts-form");

const mapStateToProps = (state) => ({
  current_user: state.current_user,
  initialValues: {
    is_packer_alert_enabled: state.edit_product?.data?.is_packer_alert_enabled,
    packer_alert: state.edit_product?.data?.packer_alert,
    is_warehouseman_alert_enabled:
      state.edit_product?.data?.is_warehouseman_alert_enabled,
    warehouseman_alert: state.edit_product?.data?.warehouseman_alert,
    show_as_one_line: state.edit_product?.data?.show_as_one_line,
    has_ean: state.edit_product?.data?.has_ean,
    has_qr_code: state.edit_product?.data?.has_qr_code,
  },
  checkValues: {
    is_packer_alert_enabled: selector(state, "is_packer_alert_enabled"),
    is_warehouseman_alert_enabled: selector(
      state,
      "is_warehouseman_alert_enabled"
    ),
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (id, data) => dispatch(updateProduct(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "alerts-form",
    enableReinitialize: true,
  })(withRouter(AlertsForm))
);
