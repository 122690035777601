import axios from "axios";
import queryString from "query-string";

import { getCookie } from "./cookies";

export const url = "https://api.wms.tradesk.pl";
// export const url = "http://localhost:3000";
// export const url = "http://192.168.10.131:3000";

export const ws_url = "wss://api.wms.tradesk.pl:3334/cable";
// export const ws_url = "ws://localhost:8080/cable";

export const api_url = `${url}/v1/`;
export const api_url_v2 = `${url}/v2/`;

axios.defaults.headers.common["Authorization"] = getCookie("token");

export const client = axios.create({ baseURL: api_url });
export const client_v2 = axios.create({
  baseURL: api_url_v2,
});

client_v2.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const handleErrors = (data) => {
  try {
    const errors = {};

    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[pointer.split("/")[pointer.split("/").length - 1]] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const parseQuery = (data) =>
  queryString.parse(data, { arrayFormat: "bracket" });

export const stringifyQuery = (data) =>
  queryString.stringify(data, {
    arrayFormat: "bracket",
    skipEmptyString: true,
    skipNull: true,
  });
